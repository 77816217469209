import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import './index.scss';
import { getMonthlyARFcReportDataURL, getMonthlyARPtReportDataURL } from "helpers/backend_helper";
import { isExpireToken } from "slices/thunk";
import back from "../../../assets/images/back-arrow.svg";
import { useLoader } from "provider/LoadingContext";

interface ARMonthlySummaryDetailsProps {
    isAr: boolean;
    setIsAr: React.Dispatch<React.SetStateAction<boolean>>;
}

const ARMonthlySummaryDetails: React.FC<ARMonthlySummaryDetailsProps> = ({ isAr, setIsAr }) => {
    const { setIsLoading } = useLoader();
    const navigate = useNavigate();
    const details = JSON.parse(localStorage.getItem("ar-summary-details") || '{}');
    const [summaryHeaderDetails, setSummaryHeaderDetails] = useState<any[]>([details]);
    const clients = JSON.parse(localStorage.getItem("selected-client") || '');
    const [summaryDetails, setSummaryDetails] = useState<any[]>([]);
    const [expandedRows, setExpandedRows] = useState<any[]>([]);
    // Currency formatting function
    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(value);
    };

    // Custom cell component
    const BoldCell = ({ value }) => (
        <span><b>{value}</b></span>
    );

    const columns = [
        {
            name: <b></b>,
            selector: row => row.insurance_type_name,
            cell: row => <BoldCell value={`${row.insurance_type_name} Subtotal`} />,
            sortable: false,
        },
        {
            name: <div style={{ textAlign: 'end', width: '100%' }}><b>AR for this month</b></div>,
            sortable: false,
            selector: row => row.ar_for_this_month,
            format: row => formatCurrency(row.ar_for_this_month),
            cell: row => (
                <div style={{ textAlign: 'end', width: '100%' }}>
                    {formatCurrency(row.ar_for_this_month)}
                </div>
            ),
        },
        {
            name: <div style={{ textAlign: 'end', width: '100%' }}><b>Net for this month</b></div>,
            sortable: false,
            selector: row => row.net_for_this_month,
            cell: row => (
                <div style={{ textAlign: 'end', width: '100%' }}>
                    {formatCurrency(row.net_for_this_month)}
                </div>
            ),
        },
        {
            name: <div style={{ textAlign: 'end', width: '100%' }}><b>Current Contractual</b></div>,
            sortable: false,
            cell: row => (
                <div style={{ textAlign: 'end', width: '100%' }}>
                    {formatCurrency(row.current_contractual)}
                </div>
            ),
        },
        {
            name: <div style={{ textAlign: 'end', width: '100%' }}><b>Net to Gross %</b></div>,
            sortable: false,
            selector: row => {
                const val = parseFloat(row.net_to_gross_percentage).toFixed(2);
                return `${val}%`;
            },
            cell: row => (
                <div style={{ textAlign: 'end', width: '100%' }}>
                    {`${parseFloat(row.net_to_gross_percentage).toFixed(2)}%`}
                </div>
            ),
        },

        {
            name: <div style={{ textAlign: 'end', width: '100%' }}><b>Prior Contractual %</b></div>,
            sortable: false,
            selector: row => row.prior_contractual_percentage !== null
                ? parseFloat(row.prior_contractual_percentage).toFixed(2) + "%"
                : "N/A",
            cell: row => (
                <div style={{ textAlign: 'end', width: '100%' }}>
                    {row.prior_contractual_percentage !== null
                        ? `${parseFloat(row.prior_contractual_percentage).toFixed(2)}%`
                        : "N/A"}
                </div>
            )
        },

        {
            // name: <span style={{ fontWeight: 'bold', textAlign: 'center', width: '90%' }}>Current Contractual %</span>,
            name: <div style={{ textAlign: 'end', width: '100%' }}><b>Current Contractual %</b></div>,
            sortable: false,
            selector: row => {
                const val = parseFloat(row.current_contractual_percentage).toFixed(2);
                return `${val}%`;
            },
            cell: row => (
                <div style={{ textAlign: 'end', width: '100%' }}>
                    {`${parseFloat(row.current_contractual_percentage).toFixed(2)}%`}
                </div>
            ),
        },

    ];

    const getMonthlyARReportData = async () => {
        try {
            setIsLoading(true);
            const body = {
                clientId: clients.user_id,
                monthYear: summaryHeaderDetails[0].month_date
            };
            let response = await getMonthlyARFcReportDataURL(body);
            if (response) {

                const fetchedSummaryDetails = response.data;
                setSummaryDetails(fetchedSummaryDetails);

                // Fetch expandable row data for each row and store in expandedRows state
                const expandedDataPromises = fetchedSummaryDetails.map(async (row: any) => {
                    const expandBody = {
                        clientId: clients.user_id,
                        monthYear: summaryHeaderDetails[0].month_date,
                        insuranceTypeId: row.insurance_type_id,
                    };
                    const result = await getMonthlyARPtReportDataURL(expandBody);
                    return {
                        ...row,
                        expandedData: result?.data || []
                    };
                });

                const expandedDataResults = await Promise.all(expandedDataPromises);
                setExpandedRows(expandedDataResults);
                setIsLoading(false);

            } else {
                console.error("Failed to fetch data");
                if (response.code === '1') {
                    isExpireToken(navigate);
                }
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.error("Error fetching data:", error);
        }
    };

    // const combinedArray = [
    //     ...summaryHeaderDetails.map(item => ({ ...item, isSummaryRow: true, insurance_type_name: 'Total AR' })),
    //     ...summaryDetails.map(item => ({ ...item, isSummaryRow: false })),
    // ];

    useEffect(() => {
        getMonthlyARReportData();
    }, []);

    const ExpandableComponent = ({ data }) => {
        return (
            <div className="rdt_table_details">
                <div>
                    {data.expandedData.length > 0 ? (
                        <table>
                            <tbody>
                                {data.expandedData.map((row: any) => (
                                    row.net_for_this_month !== 0 && (row.ar_for_this_month !== 0 || row.ar_for_this_month === null)) && (
                                        <tr key={row.patient_type_id}>
                                            <td >{data.insurance_type_name} {row.Patient_Type_Abbreviation}</td>
                                            <td className="text-end">{row.ar_for_this_month ? formatCurrency(row.ar_for_this_month) : '$0.00'}</td>
                                            <td className="text-end">{row.net_for_this_month ? formatCurrency(row.net_for_this_month) : '$0.00'}</td>
                                            <td className="text-end">{row.current_contractual ? formatCurrency(row.current_contractual) : '$0.00'}</td>
                                            <td className="text-end">{row.net_to_gross_percentage ? `${parseFloat(row.net_to_gross_percentage).toFixed(2)}%` : '0.0%'}</td>
                                            <td className="text-end">{row.prior_contractual_percentage ? `${parseFloat(row.prior_contractual_percentage).toFixed(2)}%` : 'N/A'}</td>
                                            <td className="text-end">{row.current_contractual_percentage ? `${parseFloat(row.current_contractual_percentage).toFixed(2)}%` : '0.0%'}</td>
                                        </tr>

                                    )
                                )}
                            </tbody>
                        </table>
                    ) : (
                        <p>No Data Available</p>
                    )}
                </div>
            </div>
        );
    };

    // const ExpandableComponent = ({ data }) => {
    //     const [apiData, setApiData]: any = useState([]);
    //     useEffect(() => {
    //         const getMonthlyARPtReportData = async () => {
    //             const body = {
    //                 clientId: clients.user_id,
    //                 monthYear: summaryHeaderDetails[0].month_date,
    //                 insuranceTypeId: data.Insurance_Type_Id
    //             };
    //             const result = await getMonthlyARPtReportDataURL(body);
    //             if (result && result.data) {
    //                 setApiData(result.data);
    //             } else {
    //                 console.error("Failed to fetch data");
    //                 setApiData([]);
    //             }
    //         };
    //         getMonthlyARPtReportData();
    //     }, [data]);

    //     // Render table rows
    //     const renderTableRows = () => {
    //         return apiData.map(row => (
    //             <tr key={row.patient_type_id}>
    //                 <td >{row.Patient_Type_Description}</td>
    //                 <td className="text-end">{row.ar_for_this_month ? formatCurrency(row.ar_for_this_month) : ''}</td>
    //                 <td className="text-end">{row.net_for_this_month ? formatCurrency(row.net_for_this_month) : ''}</td>
    //                 <td className="text-end">{row.net_to_gross_percentage ? `${parseFloat(row.net_to_gross_percentage).toFixed(2)}%` : ''}</td>
    //                 <td className="text-end">{row.current_contractual ? `${parseFloat(row.current_contractual).toFixed(2)}%` : ''}</td>
    //             </tr>
    //         ));
    //     };

    //     return (
    //         <div className="rdt_table_details" >
    //             <div >
    //                 {apiData.length > 0 ? (
    //                     <table>
    //                         <tbody>
    //                             {renderTableRows()}
    //                         </tbody>
    //                     </table>
    //                 ) : (
    //                     <p>Loading...</p>
    //                 )}
    //             </div>
    //         </div>
    //     );
    // };

    const handleClick = (item) => {
        setIsAr(false)
    };

    const getMonthYear = (input) => {
        const [month, year] = input.split('-');
        const monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        const monthNumber = parseInt(month, 10);
        const monthName = monthNames[monthNumber - 1];
        return `${monthName} ${year}`;
    };

    return (
        <React.Fragment>
            {/* <div className="page-content"> */}
            <Container fluid={true}>
                <div>
                    <div className="d-flex align-items-center justify-content-between commen_top">
                        <div className="d-flex align-items-center">
                            <a className="me-3" onClick={handleClick} >
                                <img
                                    src={back}
                                    alt=""
                                    className="rounded-circle"
                                    height="30"
                                />
                            </a>
                            <h4 className="mb-sm-0 font-size-18">{getMonthYear(summaryHeaderDetails[0].month_date)}</h4>
                        </div>
                    </div>
                    <DataTable
                        columns={columns}
                        data={expandedRows}
                        expandableRows
                        expandableRowExpanded={(row) => !row.isSummaryRow}
                        expandableRowsComponent={ExpandableComponent}
                        expandableRowDisabled={row => row.isSummaryRow}
                    />
                </div>
            </Container>
            {/* </div> */}
        </React.Fragment>
    );
}

export default ARMonthlySummaryDetails;